import React from 'react';
import TeamPicBottom from 'assets/images/team-picture.png';
import { useWindowDimensions } from 'hooks';
import { Members } from "./components";
import { OrangeButton } from 'components';

export const Team = () => {
  const { width } = useWindowDimensions();

  return (
    <div className='flex flex-col bg-dark-blue pb-64 relative lg:pb-48 sm:!pb-20 overflow-hidden'>
      <section className='relative w-full flex flex-col'>
        <div className='pt-[66.5%] relative'>
          <img className={`absolute top-0 left-0 w-full h-full object-cover grayscale-[30%]`} src={TeamPicBottom} />
          {width < 481 && <div className='absolute bottom-0 left-0 -translate-x-1/2 translate-y-1/3  border-4 border-white rounded-full overflow-hidden w-[12rem] h-[12rem]'>
            <div className='bg-orange absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full w-[90%] h-[90%]' />
          </div>}
        </div>
        <div className={'flex flex-col absolute bottom-48 right-20 lg:bottom-32 lg:right-10 sm:static sm:items-end sm:mt-2.5 z-20'}>
          <p className='text-6xl text-white font-bold mb-2.5 uppercase lg:text-4xl sm:px-3 sm:!text-3xl'>Wondder team</p>
          <p className='text-2xl text-white text-left max-w-[40rem] lg:text-lg lg:max-w-[32rem] sm:pr-4 sm:pl-16 sm:!text-base'>
            We’re a young, fast-growing company based in Berlin, led by experienced entrepreneurs.
            Our mission is to transform and empower people and organizations.
            Our international team of passionate innovators and tech-enthusiasts is on a mission to create measurable results for inclusion,
            diversity and equality.
          </p>
        </div>
      </section>
      <Members />
      <div className='flex flex-col items-center mt-24'>
        <p className='text-6xl text-orange font-bold mb-8 uppercase lg:text-4xl sm:!text-[32px] sm:mb-2'>JOIN OUR TEAM</p>
        <p className='text-base text-white text-center max-w-[45rem] lg:max-w-[38rem] sm:px-8'>We are on an ambitious mission to transform and empower people using VR and ethical AI. We value the contributions of our team, and as we grow, we&apos;re always looking for talented, driven people to join our ranks!</p>
        <OrangeButton href='/about-us#careers' className='mt-8 uppercase sm:mt-2'>Opportunities</OrangeButton>
      </div>
      <div className={`absolute top-[8%] bg-transparent border-4 border-orange rounded-full w-[92vw] h-[92vw] -left-1/2 z-10 sm:hidden`} >
        <div className='absolute top-[12%]  right-0 translate-x-1/3 border-4 border-white rounded-full overflow-hidden w-[23rem] h-[23rem] lg:w-[14rem] lg:h-[14rem] lg:right-[14%] lg:top-0'>
          <div className='bg-orange absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full w-[90%] h-[90%]' />
        </div>
      </div>
      {width < 481 && <div className={`absolute top-[5.5%] bg-transparent border-4 border-orange rounded-full w-[20rem] h-[20rem] -left-1/2 z-10`} />}
      <div className={`absolute top-[25%] bg-orange rounded-full w-[92vw] h-[92vw] -right-1/2 z-10 sm:w-[65rem] sm:h-[65rem] sm:top-[15%]`} />
      <div className={`absolute top-[35%] bg-[#41546D] rounded-full w-[137vw] h-[137vw] -left-3/4 z-10 sm:w-[58rem] sm:h-[58rem] sm:top-[37%]`} />
      <div className={`absolute top-[52%] border-4 border-[#41546D] rounded-full w-[137vw] h-[137vw] -right-3/4 z-10 sm:w-[46rem] sm:h-[46rem] sm:top-[53%] sm:left-1/4`} />
      <div className={`absolute top-[73%] bg-orange rounded-full w-[92vw] h-[92vw] -left-1/2 z-10 sm:w-[32rem] sm:h-[32rem] sm:top-[68%]`} />
    </div>
  );
};
