import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Home,
  AboutUs,
  SuccessStories,
  OurSolutions,
  ContactUs,
  Onboard,
  OnboardAdvancedDe,
  RoundTable,
  Impressum,
  PrivacyPolicy,
  MihaiLandingPage,
  Gpn,
  WhenIWasYourAge,
  Team,
  PressRelease,
} from "pages";

import { Header, Footer } from "components";
// import { Blog } from "pages/Blog";
// import { BlogDetails } from "pages/BlogDetails";

function App() {
  const [subdomain, setSubdomain] = useState("");

  useEffect(() => {
    const currentSubdomain = window.location.host.split(".")[0];
    setSubdomain(currentSubdomain);
  }, []);

  return (
    <div className="App min-h-[100vh] flex flex-col">
      <Router>
        {subdomain === "mihai" ? (
          <Routes>
            <Route path="/" element={<MihaiLandingPage />} />
          </Routes>
        ) : (
          <>
            <Header />
            <div
              className={`flex-1 ${
                window.location.pathname !== "/mihai"
                  ? "pt-[101px] xl:pt-16"
                  : ""
              }`}
            >
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/success-stories" element={<SuccessStories />} />
                <Route path="/our-solutions" element={<OurSolutions />} />
                <Route path="/contact" element={<ContactUs />} />
                {/* <Route path="/blog" element={<Blog />} /> */}
                {/* <Route path="/blog/:slug" element={<BlogDetails />} /> */}
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/roundtable" element={<RoundTable />} />
                <Route
                  path="/roundtable-ld"
                  element={<RoundTable product="LD" />}
                />
                <Route path="/onboard" element={<Onboard />} />
                <Route path="/onboard_advance_en" element={<Onboard />} />
                <Route path="/onboard_axr_en" element={<Onboard />} />
                <Route
                  path="/onboard_advance_de"
                  element={<OnboardAdvancedDe />}
                />
                <Route path="/onboard_axr_de" element={<OnboardAdvancedDe />} />
                <Route path="/mihai" element={<MihaiLandingPage />} />
                <Route path="/gpn" element={<Gpn />} />
                <Route
                  path="/when-i-was-your-age"
                  element={<WhenIWasYourAge />}
                />
                <Route path="/team" element={<Team />} />
                <Route path="/press-release" element={<PressRelease />} />
                <Route path="*" element={<Home />} />
              </Routes>
            </div>
            <Footer />
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
