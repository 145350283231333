import React, { useState, useRef, useEffect } from 'react';
import TeamPicTop from 'assets/images/Team-pic.png';
import TeamPicBottom from 'assets/images/team-picture.png';
import { AboutSection2, AboutSection3, AboutSection4 } from './components';
import { useScrollY } from "hooks";
import { useWindowDimensions } from "hooks";
import { OrangeButton } from 'components';

export const AboutUs = () => {
  const blockRef = useRef();
  const teamPicRef = useRef();
  const scrollY = useScrollY();
  const [visibleGrayBlock, setVisibleGrayBlock] = useState(false);
  const [teamPicActive, setTeamPicActive] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (blockRef.current.offsetTop + window.innerHeight * 1.4 < scrollY) {
      visibleGrayBlock || setVisibleGrayBlock(true);
    } else {
      visibleGrayBlock && setVisibleGrayBlock(false);
    }

    if (teamPicRef.current.getBoundingClientRect().y < window.innerHeight * 0.7) setTeamPicActive(true);
  }, [scrollY, visibleGrayBlock]);

  return (
    <div className='flex flex-col bg-[#1f3655] overflow-hidden'>
      <section className={`relative pt-[46.3%] w-full overflow-hidden`}>
        <img className={`absolute top-0 left-0 w-full h-full object-cover`} src={TeamPicTop} />
      </section>
      <div ref={blockRef} className='flex flex-col relative'>
        {/* {width > 900 && <div className={`absolute bottom-[5%] bg-dark-blue rounded-full w-[95vw] h-[95vw] -right-1/3 ${visibleGrayBlock ? 'opacity-100' : 'opacity-0'}`} />} */}
        <AboutSection2 />
        <AboutSection3 />
        <section ref={teamPicRef} className='relative w-full pt-[66.5%] mt-32 z-20 lg:mt-20'>
          <img className={`absolute top-0 left-0 w-full h-full object-cover  duration-700 delay-100 ease-in-out ${teamPicActive ? 'translate-x-0 w-full' : 'w-0 translate-x-full'}`} src={TeamPicBottom} />
          {width > 900 && <div className={'flex flex-col items-center absolute bottom-10 left-1/2 -translate-x-1/2'}>
            <p className='text-7xl text-white font-bold mb-2.5 uppercase'>Our team</p>
            <p className='text-base text-white text-center max-w-[30rem]'>
              We&apos;re based in Berlin, and our team hails from a dozen countries and many fields of expertise: from Integral Psychology,
              Film, Computer Science, Electrical Engineering, and more.
              We are led by  an experienced founders team of serial entrepreneurs: Mihai Streza, Daniel Houlden and Damir Simovski.
            </p>
            <OrangeButton href='/team' className='uppercase mt-6 w-full text-center'>Meet the team</OrangeButton>
          </div>}
        </section>
        {width < 901 && <div className={'flex flex-col items-center bg-dark-blue-gradient-to-bottom pb-20 rounded-b-full px-8 -mt-10 z-30 relative'}>
          <p className='text-3xl text-white font-bold mb-2.5 uppercase'>Our team</p>
          <p className='text-base text-white text-center max-w-[30rem]'>
            We&apos;re based in Berlin, and our team hails from a dozen countries and many fields of expertise: from Integral Psychology,
            Film, Computer Science, Electrical Engineering, and more.
            We are led by  an experienced founders team of serial entrepreneurs: Mihai Streza, Daniel Houlden and Damir Simovski.
          </p>
        </div>}
        <AboutSection4 />
      </div>
    </div>
  );
};

