import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Logo } from "components";
import Linkedin from 'assets/images/linkedin.svg';

export const Footer = () => {
  if (window.location.pathname === '/mihai') return null;

  return (
    <div className='flex flex-col relative bg-white px-25 pt-8 z-100'>
      <div className='flex justify-between items-center px-20 mb-5 xl:px-10 md:!px-8'>
        <div className='flex items-start md:flex-col'>
          <div className='flex flex-col mr-32 xl:mr-20 lg:!mr-10 md:mb-4'>
            <Link className='duration-200 text-xl text-orange mb-6 xl:mb-4 xl:text-lg md:!mb-3' to="/our-solutions">Our solutions</Link>
            <HashLink className='text-black duration-200 text-base mb-3 min-lg:hover:text-orange xl:mb-2 xl:text-sm' to="/our-solutions#leadership-training">Leadership Development</HashLink>
            <HashLink className='text-black duration-200 text-base mb-3 min-lg:hover:text-orange xl:mb-2 xl:text-sm' to="/our-solutions#dei-training">DEI Training</HashLink>
          </div>
          <div className='flex flex-col mr-32  xl:mr-20 lg:!mr-10 md:mb-4'>
            <Link className='duration-200 text-xl text-orange mb-6 xl:mb-4 xl:text-lg md:!mb-3' to="/about-us">About Us</Link>
            <HashLink className='text-black duration-200 text-base mb-3 min-lg:hover:text-orange xl:mb-2 xl:text-sm' to="/about-us#meet-the-founders">Meet the Founders</HashLink>
            <HashLink className='text-black duration-200 text-base mb-3 min-lg:hover:text-orange xl:mb-2 xl:text-sm' to="/about-us#culture-at-wondder">Culture at wondder</HashLink>
            <HashLink className='text-black duration-200 text-base mb-3 min-lg:hover:text-orange xl:mb-2 xl:text-sm' to="/about-us#careers">Careers</HashLink>
            <Link className='text-black duration-200 text-base mb-3 min-lg:hover:text-orange xl:mb-2 xl:text-sm' to="/team">Team</Link>
            <Link className='text-black duration-200 text-base mb-3 min-lg:hover:text-orange xl:mb-2 xl:text-sm' to="/press-release">PR</Link>
          </div>
          <div className='flex flex-col mr-32  xl:mr-20 lg:!mr-10 md:mb-4'>
            <Link className='duration-200 text-xl text-orange mb-6 xl:mb-4 xl:text-lg md:!mb-3' to="/contact">Contact</Link>
            <HashLink className='text-black duration-200 text-base mb-3 min-lg:hover:text-orange xl:mb-2 xl:text-sm' to="/contact#book-a-demo">Book a Demo</HashLink>
          </div>
        </div>
        <div className='flex flex-col items-center gap-y-4'>
          <Logo className='md:!w-24 md:!h-24' />
          <a className='w-10 h-10' href='https://www.linkedin.com/company/wondder/' target='_blank' rel="noreferrer">
            <img src={Linkedin} alt='linkedin'/>
          </a>
        </div>
      </div>
      <div className='flex justify-center bg-blue py-4 md:py-6'>
        <div className='flex justify-between max-w-[70%] w-full xl:max-w-[unset] xl:px-10 md:!px-8 md:flex-row-reverse md:flex-wrap-reverse md:justify-end'>
          <p className='text-white xl:text-sm md:!text-xs md:mt-8'>Copyright © {new Date().getFullYear()} VaRc GmbH All Rights Reserved</p>
          <Link className="text-white text-base font-bold	min-lg:hover:text-orange duration-200 xl:text-sm md:ml-6" to="/privacy-policy">Privacy Policy</Link>
          <Link className="text-white text-base font-bold	min-lg:hover:text-orange duration-200 xl:text-sm" to="/impressum">Impressum</Link>
        </div>
      </div>
    </div>
  );
};
