import React from 'react';

export const ContactUsTeamSection = () => {

  return (
    <div className='flex flex-col items-center w-full'>
      <p className='text-4xl text-white font-bold uppercase mt-40 lg:mt-32 lg:text-2xl sm:!mt-20 mb-20 sm:mb-10'>Contact us for:</p>
      <div className='flex justify-center w-full sm:flex-col sm:items-center  md:flex-wrap md:justify-center'>
        <div className='flex flex-col w-1/3 md:w-1/2 sm:!w-full items-center mb-20 sm:mb-10'>
          <div className='flex flex-col justify-center items-center text-2xl mt-6 lg:text-lg sm:text-center'>
            <p className='text-orange font-bold'>Strategy & Investments</p>
            <p className='text-white uppercase my-3'>MIHAI STREZA</p>
            <p className='text-white font-normal cursor-default select-none'>mihai@wondder.io</p>
          </div>
        </div>
        <div className='flex flex-col w-1/3 md:w-1/2 sm:!w-full  items-center mb-20 sm:mb-10'>
          <div className='flex flex-col justify-center items-center text-2xl mt-6 lg:text-lg'>
            <p className='text-orange font-bold'>Operations</p>
            <p className='text-white uppercase my-3'>FRANCESCA LISI</p>
            <p className='text-white font-normal cursor-default select-none'>francesca.lisi@wondder.io</p>
          </div>
        </div>
        <div className='flex flex-col w-1/3 md:w-1/2 sm:!w-full items-center mb-20 sm:mb-10'>
          <div className='flex flex-col justify-center items-center text-2xl mt-6 lg:text-lg'>
            <p className='text-orange font-bold'>Sales</p>
            <p className='text-white uppercase my-3'>Alessio Dell&apos;Anna</p>
            <p className='text-white font-normal cursor-default select-none'>alessio.dellanna@wondder.io</p>
          </div>
        </div>
      </div>
    </div>
  );
};
