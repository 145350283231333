import React, { useEffect, useState, useRef } from 'react';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import Puma from 'assets/images/puma-min.jpg';
import PumaCenter from 'assets/images/puma-center.png';
import Adidas from 'assets/images/adidas-min.jpg';
import AdidasVR from 'assets/images/adidas-vr-new.png';
import Airbus from 'assets/images/airbus-min.jpg';
import AirbusAlexis from 'assets/images/airbus-alexis.png';
import Accenture from 'assets/images/accenture-min.jpg';
import AccentureVR from 'assets/images/accenture-vr.png';

export const SuccessStoriesSection2 = () => {
  const sectionRef = useRef();
  const pumaRef = useRef();
  const airbusRef = useRef();
  const adidasRef = useRef();
  const accentureRef = useRef();
  const [activeSection, setActiveSection] = useState(false);

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActiveSection, [0.4, 0.8]);
  }, []);

  return (
    <section className='flex relative flex-col'>
      <div ref={sectionRef} className='flex flex-col py-52 max-w-[70vw] px-10 mx-auto lg:py-36 sm:max-w-[unset] sm:!py-24'>
        <p className={cn('mb-12 text-5xl font-bold text-white translate-x-1/2 opacity-0 duration-300 lg:text-3xl lg:mb-8', { '!translate-x-0  opacity-100': activeSection })}>TOGETHER INTO THE FUTURE OF LEADERSHIP</p>
        <p className={cn('text-xl text-white -translate-x-1/2 opacity-0 duration-300 lg:text-base', { '!translate-x-0  opacity-100': activeSection })}>As a Berlin-based startup, we&apos;ve successfully deployed cutting-edge trainings at some of the largest companies,
          expanding the VR circle of progressive thinkers.
          Join us in this transformative journey towards a future of innovation and inclusive learning!</p>
      </div>
      <div className='flex flex-col gap-4 lg:gap-2 sm:!gap-0'>
        <div className='relative pt-[45%] sm:pt-[67%]'>
          <img src={Puma} className='absolute top-0 left-0 w-full h-full object-cover opacity-50' alt="puma" loading="lazy" />
          <div ref={pumaRef} className='absolute top-full right-44 w-[32vw] h-[32vw] -translate-y-1/2 z-20'>
            <img src={PumaCenter} className='absolute top-[19%] left-[15%] w-[67%] h-[67%] object-contain sm:hidden' alt="Puma Center" loading="lazy" />
          </div>
        </div>
        <div className='relative pt-[45%] sm:pt-[67%]'>
          <img src={Airbus} className='absolute top-0 left-0 w-full h-full object-cover opacity-50 object-[82%]' alt="Airbus" loading="lazy" />
          <div ref={airbusRef} className='absolute top-full left-44 w-[32vw] h-[32vw] -translate-y-1/2 z-20'>
            <img src={AirbusAlexis} className='absolute top-[19%] left-[15%] w-[67%] h-[67%] object-contain sm:hidden' alt="Airbus Alexis" loading="lazy" />
          </div>
        </div>
        <div className='relative pt-[45%] sm:pt-[67%]'>
          <img src={Accenture} className='absolute top-0 left-0 w-full h-full object-cover opacity-50' alt="Accenture" loading="lazy" />
          <div ref={accentureRef} className='rounded-full overflow-hidden absolute top-full right-44 w-[21.5vw] h-[21.5vw] -translate-y-1/2 z-20'>
            <img src={AccentureVR} className='absolute top-0 left-0 w-[100%] h-[100%] object-cover object-[0,-5px] sm:hidden' alt="Accenture VR" loading="lazy" />
          </div>
        </div>
        <div className='relative pt-[45%] sm:pt-[67%]'>
          <img src={Adidas} className='absolute top-0 left-0 w-full h-full object-cover opacity-50' alt="Adidas" loading="lazy" />
          <div ref={adidasRef} className='absolute top-full left-44 w-[32vw] h-[32vw] -translate-y-[55%] z-20'>
            <img src={AdidasVR} className='absolute top-[19%] left-[15%] w-[67%] h-[67%] object-cover object-top rounded-full sm:hidden' alt="Adidas VR" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
  );
};
