import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Logo, OrangeButton } from "components";
import { useWindowDimensions } from "hooks";
import { AlessioHubspot } from 'helpers/constants';
import cn from 'classnames';

export const Header = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { width } = useWindowDimensions();
  const location = useLocation();

  useEffect(() => {
    if (!window.location.href.includes('#')) window.scrollTo(0, 0);
  }, [location]);

  if (window.location.pathname === '/mihai') return null;

  return (
    <div className={`flex items-center bg-white px-10 fixed top-0 left-0 right-0 z-[100] w-full xl:px-8 ${width < 900 ? 'justify-between' : ''}`}>
      <Logo onClick={() => setIsOpened(false)} />
      {width < 900 ?
        <>
          <div onClick={() => setIsOpened(!isOpened)} className='burger flex flex-col justify-between relative w-8 h-6 cursor-pointer'>
            <span className={cn("w-full h-0.5 bg-black duration-300", { '-rotate-45 origin-top-right': isOpened })}></span>
            <span className={cn("w-full h-0.5 bg-black duration-300", { '!w-0': isOpened })}></span>
            <span className={cn("w-full h-0.5 bg-black duration-300", { 'rotate-45 origin-bottom-right': isOpened })}></span>
          </div>
          <div className={
            cn("flex flex-col items-end fixed pt-20 px-10 bg-white top-16 left-0 right-0 bottom-0 z-[100] duration-300 ease-in-out	",
              { 'translate-x-0 pointer-events-all': isOpened },
              { 'translate-x-full pointer-events-none': !isOpened })
          }>
            <nav className='flex flex-col mb-10 text-right'>
              <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/our-solutions">Our solutions</Link>
              <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/success-stories">Success Stories</Link>
              <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/about-us">About Us</Link>
              <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/team">Team</Link>
              <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/contact">Contact</Link>
              {/* <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/blog">Blog</Link> */}
            </nav>
            <OrangeButton target="_blank" href={AlessioHubspot} className='!p-2.5 !text-2xl'>Book a Demo</OrangeButton>
          </div>
        </>
        :
        <>
          <nav className='flex items-center ml-auto	'>
            <Link className='mr-12 text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base xl:mr-6' to="/our-solutions">Our solutions</Link>
            <Link className='mr-12 text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base xl:mr-6' to="/success-stories">Success Stories</Link>
            <Link className='mr-12 text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base xl:mr-6' to="/about-us">About Us</Link>
            <Link className='mr-12 text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base xl:mr-6' to="/team">Team</Link>
            <Link className='mr-12 text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base xl:mr-6' to="/contact">Contact</Link>
            {/* <Link className='mr-12 text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base xl:mr-6' to="/blog">Blog</Link> */}
          </nav>
          <OrangeButton target="_blank" href={AlessioHubspot} >Book a Demo</OrangeButton>
        </>
      }
    </div>
  );
};
