import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useScrollY } from "hooks";
import cn from 'classnames';
import Alexis from 'assets/images/alexis.png';
import Carolina from 'assets/images/girl.png';
import Francesca from 'assets/images/Francesca.png';
import Junus from 'assets/images/junus-circle.png';
import Lilit from 'assets/images/lilit.png';
import Mihai from 'assets/images/mihai-new.png';
import Damir from 'assets/images/damir-new.png';
import Adrien from 'assets/images/adrien-new.png';
import { useWindowDimensions } from "hooks";
import classNames from 'classnames';

export const AboutSection4 = () => {
  const blockRef = useRef();
  const joinUsRef = useRef();
  const scrollY = useScrollY();
  const [joinUsActive, setJoinUsActive] = useState(false);
  const [active, setActive] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 900) {
      if (joinUsRef.current.getBoundingClientRect().y < window.innerHeight * 0.7) setJoinUsActive(true);
    }
  }, [scrollY, width]);

  const handleScroll = useCallback(() => {
    if (blockRef.current.offsetTop + window.innerHeight * 0.9 < window.scrollY) {
      if (!active) setActive(true);
    } else {
      active && setActive(false);
    }
  }, [active]);

  useEffect(() => {
    if (width > 900) window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, width]);

  return (
    <section ref={blockRef} className='flex items-end overflow-hidden pt-32 md:pt-20 relative pb-80 lg:pb-48 md:flex-col md:!pb-0'>
      {width > 900 && <div className='flex'>
        <div className="w-[75vw] h-[75vw] relative rounded-full border-8 border-white -translate-x-[45%] lg:-translate-x-[40%] z-20">
          <div id='careers' className='w-[70%] h-[70%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
            <span className='bg-gray rounded-full absolute w-full h-full top-0 left-0' />
            <div ref={joinUsRef} className='absolute top-1/2 left-1/2  -translate-y-1/2 max-w-[20rem] flex flex-col z-20 text-blue lg:left-[40%] lg:max-w-[18rem]'>
              <p className={cn('text-4xl font-bold mb-8 translate-x-1/2 opacity-0 duration-300 lg:text-2xl', { '!translate-x-0  opacity-100': joinUsActive })}>JOIN US!</p>
              <p className={cn('-translate-x-1/2 opacity-0 duration-300 text-justify lg:text-sm', { '!translate-x-0  opacity-100': joinUsActive })}>We are on an ambitious mission to transform and empower people using VR and ethical AI. We value the contributions of our team, and as we grow,
                we’re always looking for talented, driven people to join our ranks!<br /><br />
                Looking for a job in tech? Send us your CV at <a href='mailto:contact@wondder.io' className='font-bold text-orange'>contact@wondder.io</a></p>
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right	grayscale rotate-[257deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[106deg]`}>
              <img src={Adrien} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right	grayscale rotate-[235deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[126deg]`}>
              <img src={Francesca} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[213deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[144deg]`}>
              <img src={Carolina} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[191deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[165deg]`}>
              <img src={Junus} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[169deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[188deg]`}>
              <img src={Lilit} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[147deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[-146deg]`}>
              <img src={Alexis} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[125deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2  w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[-125deg]`}>
              <img src={Damir} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[103deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2  w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[-104deg]`}>
              <img src={Mihai} className='w-full h-full' />
            </div>
          </div>
        </div>
      </div>}
      {width < 901 && <div className='flex flex-col items-center gap-y-12  mx-auto text-center px-8 w-full relative overflow-hidden'>
        <p className='text-4xl text-white font-bold'>JOIN US!</p>
        <div className='flex w-full justify-center gap-12 z-10 '>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Mihai} className='w-full h-full' />
          </div>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Damir} className='w-full h-full' />
          </div>
        </div>
        <div className='flex w-full justify-center gap-12 z-10'>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Alexis} className='w-full h-full' />
          </div>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Lilit} className='w-full h-full' />
          </div>
        </div>
        <div className='flex w-full justify-center gap-12 z-10'>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Junus} className='w-full h-full' />
          </div>
          <div className={` w-36 h-36 rounded-full overflow-hidden z-10`}>
            <img src={Carolina} className='w-full h-full' />
          </div>
        </div>
        <div className='flex w-full justify-center gap-12 z-10'>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Francesca} className='w-full h-full' />
          </div>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Adrien} className='w-full h-full' />
          </div>
        </div>
        <p className='text-base text-blue z-10'>We are on an ambitious mission to transform and empower people using VR and ethical AI. We value the contributions of our team, and as we grow, we’re always looking for talented, driven people to join our ranks!</p>
        <p className='text-base text-blue z-10'>Looking for a job in tech? Send us your CV at <a href='mailto:contact@wondder.io' className='font-bold text-orange'>contact@wondder.io</a></p>
        {width < 901 && <div className={`absolute top-[50%] -left-[30rem] bg-gray rounded-full w-[94rem] h-[94rem]`} />}
      </div>}
      {width > 900 && <div className={`absolute -bottom-[15%] -right-[21%] bg-gray rounded-full w-[94vw] h-[94vw] lg:w-[60rem] lg:h-[60rem] lg:-right-[30%]`} />}
      <div className={classNames({
        ['flex flex-col z-20 absolute right-20 bottom-28 gap-y-10 max-w-[30rem] lg:max-w-[24rem] lg:right-12 lg:gap-y-6 lg:bottom-10']: width > 900,
        ['flex flex-col z-20 gap-y-10 bg-gray w-full pb-24 px-8 pt-10']: width < 901
      })}>
        <div className='flex flex-col'>
          <p className='text-4xl text-orange font-bold uppercase mb-2 lg:text-2xl md:text-center'>Our Team</p>
          <p className='text-base text-blue lg:text-sm md:text-blue md:text-center'>Our team hails from a dozen countries and many fields of expertise: from Integral Psychology and Film to Computer Science and Electrical Engineering. Together we share the vision of an awakened humanity, happy, healthy, aware, caring for themselves with joy!</p>
        </div>
        <div className='flex flex-col'>
          <p className='text-4xl text-orange font-bold uppercase mb-2 lg:text-2xl md:text-center'>Our Mission</p>
          <p className='text-base text-blue lg:text-sm md:text-blue md:text-center'>Our mission is to create and bring training in Virtual Reality to 1 billion people.</p>
        </div>
        <div className='flex flex-col'>
          <p className='text-4xl text-orange font-bold uppercase mb-2 lg:text-2xl md:text-center'>OUR OFFER</p>
          <p className='text-base text-blue lg:text-sm md:text-blue md:text-center'>We develop immersive learning methods based on proven scientific research to develop professional skills, reduce bias, and grow mental health. Our goal is to create measurable results by designing and implementing the next level of leadership development training. Now you can learn more about our approach to developing our innovative products.</p>
        </div>
        <div className='flex flex-col'>
          <p className='text-4xl text-orange font-bold uppercase mb-2 lg:text-2xl md:text-center'>OUR CULTURE</p>
          <p className='text-base text-blue lg:text-sm md:text-blue md:text-center'>Our culture is at the heart of everything we do. We envision an awaken  world happy, healthy, and successful people. </p>
        </div>
      </div>
    </section>
  );
};
