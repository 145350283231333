import React, { useEffect, useRef, useState } from 'react';
import { VideoPlayer } from 'components';
import Founders from 'assets/images/founders-pic.png';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import { useIsMobile } from "hooks";

const ABOUT_VIMEO_VIDEO = "https://player.vimeo.com/video/914242970?h=ce2b4d9a96&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";

export const AboutSection2 = () => {
  const sectionRef = useRef();
  const meetFoundersRed = useRef();
  const [activeSection, setActiveSection] = useState(false);
  const [activeMeetFounders, setActiveMeetFounders] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActiveSection, [0.1, 1]);
    isBlockVisibleOnScreen(meetFoundersRed.current, setActiveMeetFounders, [0.1, 1]);
  }, []);

  return (
    <section ref={sectionRef} className='flex flex-col items-center relative overflow-hidden pt-32 mb-32 md:mb-8 md:pt-20 sm:!pt-16'>
      <div className='absolute  bg-orange rounded-full w-[90vw] h-[90vw] top-[25%] -right-1/3 md:hidden' />
      <p className={cn('text-7xl text-white font-bold mb-12 z-10 translate-x-1/2 opacity-0 duration-300 lg:text-5xl sm:!text-2xl md:px-10 md:mb-8', { '!translate-x-0  opacity-100': activeSection })}>WORLD OF WONDDER</p>
      <p className={cn('text-base text-white text-justify max-w-[41rem] z-10 -translate-x-1/2 opacity-0 duration-300 lg:text-sm md:text-center md:px-10', { '!translate-x-0  opacity-100': activeSection })}>
        Our mission is to transform and empower people and organizations.
        We do this using Virtual Reality experiences and ethical Artificial Intelligence.We are a Berlin-based startup.
        We combine cutting-edge technology with the latest scientific research and a great passion for innovation.
      </p>
      <div className='flex mt-28 items-end px-20 z-10 w-full md:flex-col md:mt-16 md:px-0'>
        <VideoPlayer className='flex-1 md:w-full md:pt-[56.3%] md:translate-x-0 md:opacity-100' src={ABOUT_VIMEO_VIDEO} />
        <div ref={meetFoundersRed} className='flex flex-col flex-1 ml-8 mb-12 max-w-[26rem] min-xl:max-w-[36rem] md:max-w-[unset] md:mb-0 md:mt-10 md:items-center md:px-10 md:hidden' >
          <p className={cn('text-4xl text-white font-bold whitespace-nowrap mb-10 -translate-x-1/2 opacity-0 duration-300 ', { '!translate-x-0  opacity-100': activeMeetFounders })}>MEET THE FOUNDERS</p>
          <p className={cn('text-base text-white text-justify translate-x-1/2 opacity-0 duration-300 md:hidden', { '!translate-x-0  opacity-100': activeMeetFounders })}>wondder is run by serial entrepreneurs with global reach and amazing skills and capabilities.</p>
        </div>
      </div>
      <section className='relative pt-[55.7%] w-full mt-32 z-20'>
        <img className={`absolute top-0 left-0 w-full h-full object-cover`} src={Founders} alt="founders" />
        {mobile && <p className='text-3xl text-white font-bold whitespace-nowrap mb-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' >MEET THE FOUNDERS</p>}
      </section>
      {mobile && <p className='text-base text-white text-center mt-16 px-10'>wondder is run by serial entrepreneurs with global reach and amazing skills and capabilities.</p>}
    </section>
  );
};
