import React, { useState, useEffect } from 'react';
import { ContactUsTeamSection } from './components';
import LinkedinIcon from 'assets/images/linkedin.png';
import { useIsMobile } from "hooks";

export const ContactUs = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  return (
    <div className='flex flex-col bg-dark-blue px-20 py-36 xl:px-10 lg:py-24 sm:py-16 sm:!px-5'>
      <div className='flex items-start px-20 md:px-0 sm:flex-col-reverse'>
        <div className='flex max-w-[30rem] h-[70rem] w-full sm:mt-8 sm:min-w-full'>
          <iframe width='100%' height='100%' src="https://share-eu1.hsforms.com/1CxxCWHuSQE2wBnEnZUtTOg2elq82"></iframe>
        </div>
        <div className='flex flex-col ml-20 lg:ml-16 sm:!ml-0'>
          <div className='flex flex-col text-white'>
            <h2 className={`text-4xl lg:text-2xl sm:!text-xl font-bold mb-12 lg:mb-8 sm:!mb-6 duration-500 delay-150 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>IMPRESSUM</h2>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-200 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>VaRc GmbH</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-300 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Seat of the company: Berlin</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-[400ms] ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Register Court: Amtsgericht Charlottenburg, HRB 213567 B</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-500 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Company Address: Zähringerstraße 26, 10707 Berlin, DE</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-[600ms] ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Managing Director: Mihai Streza-Cirlan</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-[700ms] ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Email: contact@wondder.io</p>
          </div>
          {!mobile && <div className='flex mt-8'>
            <a className={`mr-5 lg:mr-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 ' : '-translate-y-1/2 opacity-0'}`} href="https://www.linkedin.com/company/wondder/" target="_blank" rel="noreferrer" ><img className='w-16 h-16 lg:w-10 lg:h-10' src={LinkedinIcon} /></a>
          </div>}
        </div>
      </div>
      {mobile && <div className='flex mt-16 justify-between sm:self-center'>
        <a className={`mr-5 lg:mr-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 ' : '-translate-y-1/2 opacity-0'}`} href="https://www.linkedin.com/company/wondder/" target="_blank" rel="noreferrer" >
          <img className='w-16 h-16' src={LinkedinIcon} />
        </a>
      </div>}
      <ContactUsTeamSection />
    </div>
  );
};
