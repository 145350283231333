import React from 'react';
import SectionBg from 'assets/images/damir-tech-new.png';
import { useWindowDimensions } from 'hooks';
import Laval from 'assets/images/alexis-min.jpg';
import Sifted from 'assets/images/siftedd.png';
import TalkinApe from 'assets/images/talking-ape.jpg';
import EuArticle from 'assets/images/eu-article.png';
import { OrangeButton } from 'components';

export const PressRelease = () => {
  const { width } = useWindowDimensions();

  return (
    <div className='flex flex-col bg-dark-blue relative overflow-hidden pb-48 sm:pb-20'>
      <section className='relative w-full sm:flex-col'>
        <div className='pt-[53.4%] relative'>
          <img className={`absolute top-0 left-0 w-full h-full object-cover`} src={SectionBg} />
        </div>
        {width < 481 && <div className='flex flex-col px-8 bg-orange pt-16 pb-32 rounded-b-full'>
          <p className='text-3xl text-white font-bold uppercase'>Press and Media</p>
          <p className='text-base text-white mt-16 mb-10'>We love working with journalists and media outlets who are telling stories about how Virtual Reality and ethical
            Artificial Intelligence is changing our world for the better.
            We are open to opportunities about the intersection of technology and the future of personal development.
          </p>
          <OrangeButton to='/contact' className='!bg-dark-blue'>Contact our press team</OrangeButton>
        </div>}
        {width > 480 && <div className='bg-orange absolute top-32 -left-[45vw] rounded-full w-[90vw] h-[90vw]' >
          <div className='flex flex-col max-w-[30rem] absolute left-[calc(45vw+80px)] top-1/2 -translate-y-1/2 lg:left-[calc(45vw+40px)] lg:max-w-[24rem] md:!left-[calc(45vw+24px)] md:!max-w-[20rem]'>
            <p className='text-[64px] text-white font-bold uppercase lg:text-4xl'>Press and<br /> Media</p>
            <p className='text-base text-white my-7 lg:my-4 lg:text-sm'>We love working with journalists and media outlets who are telling stories about how Virtual Reality and ethical
              Artificial Intelligence is changing our world for the better.
              We are open to opportunities about the intersection of technology and the future of personal development.
            </p>
            <OrangeButton to='/contact' className='!bg-dark-blue self-start'>Contact our press team</OrangeButton>
          </div>
        </div>}
      </section>
      <section className='flex flex-col items-center justify-center z-20 mt-80 px-20 relative lg:px-12 sm:mt-24'>
        <div className='bg-gray absolute top-[40%] -right-[45vw] rounded-full w-[90vw] h-[90vw] md:-right-[35vw] sm:hidden' />
        <p className='text-[64px] text-white font-bold uppercase mb-20 self-end mr-48 leading-tight	z-10 lg:text-5xl lg:mb-16 lg:mr-40 sm:!text-3xl sm:text-center sm:whitespace-nowrap sm:!mr-0 sm:!mb-10'>Press{width > 480 && <br />} Releases</p>
        <Arrows className='self-end z-20 lg:w-16 sm:!w-12 -mb-6' />
        <div className='flex flex-col gap-y-16 w-full mt-10 z-10 lg:mt-8 sm:!mt-0 sm:gap-y-10'>
          <div className='flex items-center gap-x-16 lg:gap-x-10 sm:flex-col-reverse'>
            <div className='flex flex-col flex-1'>
              <p className='text-base text-white mb-1'>04.11.2024</p>
              <p className='text-[32px] text-white font-semibold lg:text-2xl sm:!text-lg capitalize'>The Future of Learning: AI-Activated Virtual Reality Headsets</p>
              <p className='text-base text-white my-4 lg:text-sm'>Wondder&apos;s AI-activated VR headsets are transforming the learning landscape with hyper-personalized and immersive experiences.</p>
              <a className='text-orange underline font-bold text-lg duration-150 hover:text-white sm:text-base' target='_blank' href='https://www.talking-ape.com/the-future-of-learning-ai-activated-virtual-reality-headsets/' rel="noreferrer">Read more</a>
            </div>
            <div className='relative w-[58%] pt-[37.5%] rounded-[88px] overflow-hidden md:w-[50%] sm:!w-full sm:rounded-2xl sm:pt-[70%] mb-4'>
              <img src={TalkinApe} className='absolute top-0 left-0 w-full h-full object-cover' />
            </div>
          </div>
          <div className='flex items-center flex-row-reverse gap-x-16 lg:gap-x-10 sm:flex-col-reverse'>
            <div className='flex flex-col flex-1'>
              <p className='text-base text-blue mb-1 sm:text-white'>03.06.2023</p>
              <p className='text-[32px] text-blue font-semibold lg:text-2xl sm:!text-lg sm:text-white capitalize'>wondder develops immersive training
                to revolutionize
                the workplace</p>
              <p className='text-base text-blue my-4 lg:text-sm sm:text-white'>Immersive VR simulations are reshaping workplace training - wondder leading the way in fostering skill development and leadership growth.</p>
              <a className='text-orange underline font-bold text-lg duration-150 hover:text-white sm:text-base ' target='_blank' href='https://blog.laval-virtual.com/en/wondder-professionnal-training-virtual-reality/' rel="noreferrer">Read more</a>
            </div>
            <div className='relative w-[58%] pt-[37.5%] rounded-[88px] overflow-hidden md:w-[50%] sm:!w-full sm:rounded-2xl sm:pt-[70%] mb-4'>
              <img src={Laval} className='absolute top-0 left-0 w-full h-full object-cover' />
            </div>
          </div>
        </div>
      </section>
      <section className='flex flex-col px-20 mt-48 z-30 lg:px-12 sm:mt-20'>
        <p className='text-[64px] text-white font-bold uppercase mb-20 leading-tight  lg:text-5xl lg:mb-16 lg:mr-40 sm:!text-3xl sm:text-center sm:!mr-0 sm:!mb-10'>Wondder in{width > 480 && <br />} the News</p>
        <Arrows className='self-end z-20 lg:w-16 -mb-6' />
        <div className='flex flex-col gap-y-16 w-full mt-10 lg:mt-8 sm:!mt-0 sm:gap-y-10'>
          <div className='flex items-center flex-row-reverse gap-x-16 lg:gap-x-10 sm:flex-col-reverse'>
            <div className='flex flex-col flex-1'>
              <p className='text-base text-white mb-1'>12.03.2024</p>
              <p className='text-[32px] text-white font-semibold lg:text-2xl sm:!text-lg capitalize'>Which German Virtual Reality Startups are Influencing the Global Tech Industry?</p>
              <p className='text-base text-white my-4 lg:text-sm'>As part of Germany’s thriving tech ecosystem, wondder is pushing boundaries in immersive training and leadership development</p>
              <a className='text-orange underline font-bold text-lg duration-150 hover:text-white sm:text-base' target='_blank' href='https://eustartup.news/which-german-virtual-reality-startups-are-influencing-the-global-tech-industry/' rel="noreferrer">Read more</a>
            </div>
            <div className='relative w-[58%] pt-[37.5%] rounded-[88px] overflow-hidden md:w-[50%] sm:!w-full sm:rounded-2xl sm:pt-[70%] mb-4'>
              <img src={EuArticle} className='absolute top-0 left-0 w-full h-full object-cover' />
            </div>
          </div>
          <div className='flex items-center gap-x-16 lg:gap-x-10 sm:flex-col-reverse'>
            <div className='flex flex-col flex-1'>
              <p className='text-base text-white mb-1'>04.11.2024</p>
              <p className='text-[32px] text-white font-semibold lg:text-2xl sm:!text-lg capitalize'>Extended reality: 59 Early stage startups to watch</p>
              <p className='text-base text-white my-4 lg:text-sm'>wondder stands out as a rising tech startup by making waves on the global stage with its innovative solutions.</p>
              <a className='text-orange underline font-bold text-lg duration-150 hover:text-white sm:text-base' target='_blank' href='https://sifted.eu/pro/briefings/extended-reality' rel="noreferrer">Read more</a>
            </div>
            <div className='relative w-[58%] pt-[37.5%] rounded-[88px] overflow-hidden md:w-[50%] sm:!w-full sm:rounded-2xl sm:pt-[70%] mb-4'>
              <img src={Sifted} className='absolute top-0 left-0 w-full h-full object-cover' />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default function Arrows({ className }) {
  return (
    <svg className={className} height="61" width="83" fill="none" viewBox="0 0 83 61" xmlns="http://www.w3.org/2000/svg">
      <path d="M81.1756 29.5871L81.1782 29.59C81.6061 30.0629 81.6097 30.7995 81.1756 31.2818L56.2844 58.9387C56.0268 59.2148 55.6823 59.3634 55.3336 59.3634H38.7345C38.2381 59.3634 37.7825 59.0711 37.575 58.6074L37.5738 58.6045C37.3725 58.1573 37.4535 57.6234 37.7915 57.2463C37.7918 57.2461 37.792 57.2458 37.7923 57.2455L61.0243 31.4352L61.9275 30.4318L61.0244 29.4283L37.7912 3.61116L37.7912 3.61116L37.7888 3.60858C37.4617 3.24677 37.372 2.71462 37.5758 2.24878C37.7836 1.79186 38.2384 1.5 38.7345 1.5H55.3336C55.6894 1.5 56.0283 1.64815 56.2789 1.92413C56.2793 1.92455 56.2797 1.92498 56.2801 1.9254L81.1756 29.5871Z" stroke="white" strokeWidth="3" />
      <path d="M45.2107 29.5871L45.2134 29.59C45.6412 30.0629 45.6448 30.7995 45.2108 31.2818L20.3196 58.9387C20.0619 59.2148 19.7175 59.3634 19.3687 59.3634H2.76963C2.27322 59.3634 1.81769 59.0711 1.61019 58.6074L1.60891 58.6045C1.40766 58.1573 1.48867 57.6234 1.82666 57.2463C1.82691 57.2461 1.82716 57.2458 1.82741 57.2455L25.0594 31.4352L25.9626 30.4318L25.0595 29.4283L1.82633 3.61116L1.82633 3.61116L1.824 3.60858C1.49688 3.24677 1.4072 2.71461 1.61098 2.24877C1.81872 1.79186 2.27357 1.5 2.76963 1.5H19.3687C19.7246 1.5 20.0635 1.64815 20.3141 1.92413C20.3144 1.92455 20.3148 1.92498 20.3152 1.9254L45.2107 29.5871Z" stroke="white" strokeWidth="3" />
    </svg>
  );
}
